import * as Types from "../actions/banner";

const initialState = {
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.BANNER_RECEIVED:
            if (!action.payload) {
                return {
                    ...state
                }
            }

            return {
                ...state,
                ...action.payload
            };
        case Types.LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;

    }
};
