import ArrowLeft from "../../../../resources/images/svg/arrowLeft.svg";
import ArrowRight from "../../../../resources/images/svg/arrowRight2.svg";
import AliceCarousel from "react-alice-carousel";
import React from "react";
import styles from "./CharityImpactBlock.module.scss"
import { getCmsButtons, imageUrlFor, renderBlockText } from "../../../../util/util";
import Button from "../../widgets/button/Button";
import ButtonBlock from "../buttonBlock/ButtonBlock";

const ImpactCard = (props) => {
    const impact = props.impact
    return (
        <div key={impact._key} className={styles.impact_card}>
            <>
                <div className={styles.logo_wrapper}>
                    <img src={imageUrlFor(impact.impactLogo||impact.logo)}/>
                </div>
                <div className={styles.statistic}>
                    {renderBlockText(impact.statistic)}
                </div>
            </>
            <Button text={"View charity"} onPress={() => props.navigate(`/charity-selection#${impact.id}`)} outline/>
        </div>
    )
}

export const CharityImpactBlock = (props) => {



    const { charityImpacts } = props
    const impacts = charityImpacts.impacts
    return (
        <div className={styles.contents}>

            <h5>
                {charityImpacts.subtext}
            </h5>
            <h2>
                {renderBlockText(charityImpacts.heading)}
            </h2>
            <AliceCarousel
                items={!!impacts && impacts.map((impact) => {
                    return (
                        <ImpactCard navigate={props.navigate}
                                    key={impact._key} impact={impact}/>
                    );
                })}
                responsive={{
                    0: {items: 1},
                    550: { items: 2 },
                    803: { items: 3 },
                    1280: { items: 4 },
                    1400: { items: 5 }
                }}
                autoPlayInterval={5000}
                fadeOutAnimation
                paddingRight={20}
                paddingLeft={20}
                mouseTrackingEnabled
                stopAutoPlayOnHover={false}
                disableAutoPlayOnAction
                infinite
                renderPrevButton={() => (
                    <div className={styles.arrow_left}>
                        <img src={ArrowLeft}
                             alt={"Prev"}/>
                    </div>
                )}
                renderNextButton={() => (
                    <div className={styles.arrow_right}>
                        <img src={ArrowRight}
                             alt={"Next"}/>
                    </div>
                )}
            />
            <ButtonBlock buttons={getCmsButtons(charityImpacts.buttons)} className={styles.button} />
        </div>
    )
}