import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createActiveCampaignContact, submitContact } from "../../../redux/actions/api/contact";
import { imageUrlFor, renderBlockText } from "../../../util/util";
import useAllImagesLoaded, { useOnLoadImages } from "../../../util/hooks/useOnLoadImages";

import AliceCarousel from "react-alice-carousel";
import ArrowLeft from "../../../resources/images/svg/arrowLeftGrey.svg";
import ArrowRight from "../../../resources/images/svg/arrowRightGrey.svg";
import { BookingForm } from "./BookingForm";
import Button from "../../common/widgets/button/Button";
import { QuestionsForm } from "./QuestionsForm";
import { RequestInfoForm } from "./RequestInfoForm";
import { VideoCard } from "../../common/widgets/videoCard/VideoCard";
import classnames from "classnames";
import logo from "../../../resources/images/png/Logo_ShareMS_White.png"
import { pageReceived } from "../../../redux/actions/pages";
import smsLogo from "../../../resources/images/png/Logo_ShareMS_Colour.png"
import styles from "./LandingPage.module.scss"
import { useParams } from "react-router-dom";
import withRouter from "../../../util/hooks/withRouter";
import { CardBlock } from "../../common/blocks/cardBlock/CardBlock";

const Hero = (props) => {
    const { title, subtitle, video } = props;
    const [ videoOpen, setVideoOpen ] = useState(false);
    const onDonateClick = () => {
        window.dataLayer.push({ "event": "Exit to donation page" })
        window.open(`${window.location.origin}/donate`)
    }

    const onVideoClick = () => {
        setVideoOpen(!videoOpen)
        if (videoOpen) {
            window.dataLayer.push({ "event": "Hero video play" })
        }
    }

    return (
        <div className={ styles.hero }>
            <div className={ styles.inner }>
                <div className={ styles.left }>
                    <h1>
                        { title }
                    </h1>
                    <p>
                        { subtitle }
                    </p>
                    <Button darkgreen text={ "Donate" } onPress={ onDonateClick } />

                </div>
                <div className={ styles.right } onClick={ onVideoClick }>
                    <VideoCard video={ video.video } thumbnail={ imageUrlFor(video.thumbnail) } />
                </div>
            </div>
        </div>
    )
}

const MiniNav = (props) => {

    const onDonateClick = () => {
        window.dataLayer.push({ "event": "Exit to donation page" })
        window.open(`${window.location.origin}/donate`)
    }

    return (
        <div className={ styles.nav }>
            <img src={ smsLogo } onClick={ () => props.navigate("/") } />
            <Button darkgreen text={ "Donate" } onPress={ onDonateClick } />
        </div>
    )
}

const CtaBlock = (props) => {

    const { heading, description, image } = props

    const onDonateClick = () => {
        window.dataLayer.push({ "event": "Exit to donation page" })
        window.open(`${window.location.origin}/donate`)
    }
    return (
        <div className={ styles.cta_block }>
            <div className={ styles.inner }>
                <img src={ imageUrlFor(image) || "" } />

                <div>
                    <h2>
                        { heading }
                    </h2>
                    <p>
                        { renderBlockText(description) }
                    </p>
                </div>
                <Button darkgreen text={ "Donate" } onPress={ onDonateClick } />
            </div>
        </div>

    )
}
const SecondaryCtaBlock = (props) => {

    const { heading, description, image } = props
    const onDonateClick = () => {
        window.dataLayer.push({ "event": "Exit to donation page" })
        window.open(`${window.location.origin}/donate`)
    }

    return (
        <div className={ styles.secondary_cta_block }>
            <div className={ styles.inner }>
                {/*<img src={imageUrlFor(image) || ""}/>*/ }

                <div>
                    <div className={ styles.text }>
                        <h2>
                            { heading }
                        </h2>
                        <p>
                            { renderBlockText(description) }
                        </p>
                    </div>
                    <Button darkgreen text={ "Join us" } onPress={ onDonateClick } />

                </div>
            </div>
        </div>

    )
}


const FormBlock = (props) => {
    const { heading, description, image, type } = props


    return (
        <div className={ classnames(styles.form_block, type === "info" ? styles.info : "") }>
            <h2 className={ classnames(styles.top_heading, type === "info" ? styles.white : {}) }>
                { heading }
            </h2>
            <div className={ styles.inner }>

                <div style={ { backgroundImage: `url(${imageUrlFor(image || "")})` } } className={ styles.image } />

                <div className={ classnames(styles.text, type === "info" ? styles.white : {}) }>
                    <p>
                        { renderBlockText(description) }
                    </p>
                    {
                        type === "webinar" && <QuestionsForm />

                    }
                    {
                        type === "info" && <RequestInfoForm />
                    }
                    {
                        type === "booking" && <BookingForm />
                    }

                </div>
            </div>
        </div>

    )
}


const PhoneNumberBlock = () => {

    const onCallPress = () => {
        window.dataLayer.push({ "event": "Click to call" })
    }

    return (
        <div className={ styles.phone_number_block }>
            <div className={ styles.inner }>
                <h2>
                    Give us a call
                </h2>
                <p>
                    Would you like to speak to one of our team to find out more? Or perhaps you'd like to set up your
                    donation
                    over the phone? Give us a call - we're happy to help.
                </p>
                <a href={ "tel:0800300106" }>
                    <Button darkgreen onPress={ onCallPress }>
                        0800 300 106
                    </Button>
                </a>
            </div>
        </div>
    )
}

const CarouselCard = (props) => {
    const { heading, description, image } = props;

    return (
        <div className={ styles.carousel_card }>
            <div style={ { backgroundImage: `url(${imageUrlFor(image)})` } } className={ styles.image } />
            <div className={ styles.bottom }>
                <h4>
                    { heading }

                </h4>
                { renderBlockText(description) }
            </div>

        </div>
    )
}

const CardCarouselBlock = (props) => {
    const { heading, subheading, cards } = props
    return (
        <div className={ styles.carousel_card_block }>
            <div className={ styles.inner }>
                <h2>
                    { heading }
                </h2>
                <p>
                    { subheading }
                </p>
                <div className={ styles.carousel }>
                    <AliceCarousel
                        items={ cards.map((c, i) => <CarouselCard heading={ c.heading } description={ c.subheading }
                            image={ c.image }
                            key={ `card-${c.heading}` } />) }
                        autoPlayInterval={ 5000 }
                        fadeOutAnimation
                        responsive={ {
                            0: { items: 1 },
                            550: { items: 2 },
                            803: { items: 3 },
                            1280: { items: 4 },
                            1400: { items: 4 }
                        } }
                        mouseTrackingEnabled
                        stopAutoPlayOnHover={ false }
                        disableAutoPlayOnAction
                        infinite
                        controlsStrategy={ "responsive" }
                        renderPrevButton={ () => (
                            <img src={ ArrowLeft }
                                alt={ "Prev" } />
                        ) }
                        renderNextButton={ () => (
                            <img src={ ArrowRight }
                                alt={ "Next" } />
                        ) }
                    />

                </div>
            </div>
        </div>
    )
}

const DoubleVideoBlock = (props) => {
    const { heading, videos } = props;
    const [ videoOpen, setVideoOpen ] = useState(false)
    const onDonorVideoClick = () => {
        setVideoOpen(!videoOpen)
        if (videoOpen) {
            window.dataLayer.push({ "event": "Donor video play" })
        }
    }

    return (
        <div className={ styles.double_video }>
            <h2>
                { heading }
            </h2>
            <div className={ styles.videos } onClick={ onDonorVideoClick }>
                { videos.map((v) => (
                    <VideoCard video={ v.video } key={ v.video } thumbnail={ imageUrlFor(v.thumbnail) } />
                )) }
            </div>
        </div>
    )
}

const Footer = (props) => {

    const [ success, setSuccess ] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault()
        await createActiveCampaignContact(e.target.value)
        setSuccess(true)
    }
    const onDonateClick = () => {
        window.dataLayer.push({ "event": "Exit to donation page" })
        window.open(`${window.location.origin}/donate`)
    }

    const onCharitiesPress = () => {
        window.open(`${window.location.origin}/charities`)
    }

    return (
        <div className={ styles.footer }>
            <div className={ styles.inner }>
                <div className={ styles.left }>
                    <img src={ logo } onClick={ () => props.navigate("/") } />
                    <div className={ styles.policy }>
                        <a href={ "/privacy-policy" }>
                            Privacy Policy
                        </a>
                    </div>
                </div>
                <div className={ styles.right }>
                    <div className={ styles.top }>
                        <Button text={ "Donate" } darkgreen onPress={ onDonateClick } />
                        <Button text={ "Browse Charities" } white onPress={ onCharitiesPress } />
                    </div>
                    <div className={ styles.bottom }>

                        <p>
                            Subscribe to our newsletter
                        </p>
                        <form onSubmit={ onSubmit }>
                            <input placeholder={ "Enter your email address" } type={ "email" } />
                            <Button text={ success ? "Sent" : "Send" } darkgreen disabled={ success } type={ "submit" } />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LandingPage = (props) => {
    const [ loading, setLoading ] = useState(true);
    const [ showCards, setShowCards ] = useState(false);
    const [ scroll, setScroll ] = useState(0)
    const [ offset, setOffset ] = useState(0)
    const [ height, setHeight ] = useState(0)
    const dispatch = useDispatch();
    const { page, navigate } = props
    const delayByOneSecond = (callback) => {
        setTimeout(callback, 700);
    };

    useLayoutEffect(() => {
        const scrollToHashElement = () => {
            const { hash } = window.location;
            const elementToScroll = document.getElementById(hash?.replace("#", ""));

            if (!elementToScroll) {
                return
            }

            elementToScroll.scrollIntoView({
                behavior: "smooth",
                block: "start",
            })
        };

        if (loading) {
            return
        }

        delayByOneSecond(() => scrollToHashElement());
        // scrollToHashElement()
        window.addEventListener("hashchange", scrollToHashElement);
        return window.removeEventListener("hashchange", scrollToHashElement);
    }, [ loading ]);
    useEffect(() => {
        const landingPageQuery = `{
        heading,
        hero,
        cardsBlock,
        carouselCardsBlock,
        ctaBlock,
        formBlock,
        secondaryFormBlock,
        tertiaryFormBlock,
        doubleVideoBlock,
        secondaryCtaBlock,
        }`;


        window.sanity.fetch(`*[_type == "landingPage"]${landingPageQuery}[0]`).then((page) => {
            dispatch(pageReceived({ "landingPage": page }))
            setLoading(false);
        });

    }, [])


    const {
        hero,
        cardsBlock,
        carouselCardsBlock,
        ctaBlock,
        doubleVideoBlock,
        secondaryCtaBlock,
        formBlock,
        secondaryFormBlock,
        tertiaryFormBlock
    } = page || {};

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const parentOffset = document.getElementById("cards_block").offsetTop;
    //         const parentHeight = document.getElementById("cards_block").offsetHeight;
    //
    //         const scrollTop = window.scrollY;
    //         setScroll(scrollTop);
    //         setOffset(parentOffset);
    //         setHeight(parentHeight);
    //     };
    //
    //     window.addEventListener("scroll", handleScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    if (loading) {
        return null
    }

    return (
        <div className={ styles.page }>
            <MiniNav navigate={ navigate } />
            <Hero navigate={ navigate } title={ hero.heading } subtitle={ hero.subheading } video={ hero.video } />
            <CardBlock heading={ cardsBlock.heading } subheading={ cardsBlock.subheading } cards={ cardsBlock.cards }
                scroll={ scroll } offset={ offset } height={ height } />

            <div id={ "info-pack" }>

                <FormBlock heading={ secondaryFormBlock.heading } description={ secondaryFormBlock.content }
                    image={ secondaryFormBlock.image } type={ "info" } />
            </div>
            <CardCarouselBlock heading={ carouselCardsBlock.heading } subheading={ carouselCardsBlock.subheading }
                cards={ carouselCardsBlock.cards } />
            {/* <div id={ "webinar" }>
                <FormBlock heading={ formBlock.heading } description={ formBlock.content } image={ formBlock.image }
                    type={ "webinar" } />
            </div> */}
            <DoubleVideoBlock heading={ doubleVideoBlock.heading } videos={ doubleVideoBlock.videos } />
            <PhoneNumberBlock />

            <CtaBlock navigate={ navigate } heading={ ctaBlock.heading } description={ ctaBlock.content }
                image={ ctaBlock.image } />
            <div id={ "book-a-call" }>

                <FormBlock heading={ tertiaryFormBlock.heading } description={ tertiaryFormBlock.content }
                    image={ tertiaryFormBlock.image } type={ "booking" } />
            </div>
            <SecondaryCtaBlock navigate={ navigate } heading={ secondaryCtaBlock.heading }
                description={ secondaryCtaBlock.content }
                image={ secondaryCtaBlock.image } />
            <Footer navigate={ navigate } />
        </div>
    );
}

export default connect((state) => {
    return {
        page: state.pages.landingPage
    };
})(withRouter(LandingPage));
